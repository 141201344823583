import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import "./PostList.scss";
import RenderContent from "../../RenderContent";
import Pagination from "../../Pagination";
import { Image } from "../../Image";
import { PostListItem } from "../PostListItem";
import { PostListNavigation } from "../PostListNavigation";

export default class IndexPage extends React.Component {
  render() {
    const {
      posts,
      title,
      pageContext,
      categoryFilter,
      pathPrefix,
      siteMetadata,
      showAuthor,
      dateFormat,
    } = this.props;
    return (
      <>
        <PostListNavigation
          pathPrefix={pathPrefix}
          categoryFilter={categoryFilter}
          postSlug="/blog/"
        />
        <section className="post-list layout">
          <div className="post-list-container">
            <div className="posts">
              {posts &&
                posts.map(
                  (post, index) =>
                    post.node.slug !== "gatsby-demo" && (
                      <PostListItem
                        key={index}
                        data={post.node}
                        dateFormat={dateFormat}
                        showDate
                      />
                    )
                )}
            </div>
            <Pagination pageContext={pageContext} pathPrefix={pathPrefix} />
          </div>
        </section>
      </>
    );
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    categories {
      id
      name
      slug
      path
    }
    title
    excerpt
    date
    slug
    path
    featured_media {
      localFile {
        childImageSharp {
          fluid(maxWidth: 670, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`;
