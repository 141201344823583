import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import "./PostListNavigation.scss";

export const PostListNavigation = (props) => {
  const {
    categoryFilter: { nodes },
  } = props;
  const [location, setLocation] = useState("");
  useEffect(() => {
    setLocation(window?.location?.pathname);
  }, []);

  const handleChange = (path) => {
    if (!path) return null;
    navigate(path);
  };

  if (!nodes) return null;

  let categoriesArray = [];
  nodes.map(
    (post) =>
      post.categories &&
      post.categories.map((category) => {
        let thisCompare = categoriesArray.find(
          (compare) => compare.wordpress_id === category.wordpress_id
        );
        !thisCompare &&
          category.slug !== "uncategorized" &&
          categoriesArray.push(category);
      })
  );

  return (
    <section className="post-list-navigation layout">
      <div className="post-list-navigation-container">
        <div className="filter-container">
          <label className="filter-label" htmlFor="filter">
            Filter:
          </label>
          <select
            className="filter-select"
            name="filter"
            id="filter"
            value={location}
            onChange={(e) => handleChange(e.target.value)}
          >
            <option value="/blog/">All</option>
            {categoriesArray &&
              categoriesArray.map(
                (category, index) =>
                  category.slug !== "uncategorised" && (
                    <option key={index} value={category.path}>
                      {category.name}
                    </option>
                  )
              )}
          </select>
        </div>
      </div>
    </section>
  );
};
