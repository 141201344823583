import React from "react";
import { GatsbyLink } from "components";
import "./Pagination.scss";
import { ReactComponent as ChevronLeft } from "images/icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "images/icons/chevron-right.svg";

const Pagination = ({ pageContext, pathPrefix }) => {
  if (!pageContext) {
    return null;
  }
  const {
    previousPagePath = null,
    nextPagePath = null,
    numberOfPages = null,
  } = pageContext;
  var pages = [];
  for (var i = 0; i < numberOfPages; i++) {
    pages.push(i + 1);
  }

  if (pages.length === 1) {
    return null;
  }

  return (
    <nav className="pagination" role="navigation">
      <div className="navbar navbar-menu">
        {previousPagePath && (
          <GatsbyLink to={previousPagePath} className="prev">
            <ChevronLeft />
          </GatsbyLink>
        )}
        {pages.map((key, index) => (
          <GatsbyLink
            key={index}
            to={key !== 1 ? `${pathPrefix}page/${key}` : pathPrefix}
            activeClassName="active"
          >
            {key}
          </GatsbyLink>
        ))}
        {nextPagePath && (
          <GatsbyLink to={nextPagePath} className="next">
            <ChevronRight />
          </GatsbyLink>
        )}
      </div>
    </nav>
  );
};

export default Pagination;
